// 硬件检测项管理
<template>

    <div class='hardwareDetectionManage baseBg'>
        <div class="searchBar ofh">
            <el-form :model="searchForm"
                     style="width: 100%"
                     ref="searchForm"
                     class="fll"
                     :inline="true"
                     size="normal">
                <el-form-item label="检测项">
                    <el-input v-model="searchForm.name"
                              placeholder="请输入检测项"
                              clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="getList">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type=""
                               @click="resetSearchForm">清除</el-button>
                </el-form-item>
                <el-button type="success"
                           class="flr"
                           size="default"
                           @click="openpopEditTestItems()">新增检测项</el-button>
            </el-form>
        </div>

        <div class="listBox">
            <div class="tableBox">
                <el-table :data="tableData.data"
                          border
                          :height="tableHeight"
                          stripe>
                    <el-table-column prop="name"
                                     label="检测项"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="remark"
                                     label="描述"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="id"
                                     label="操作"
                                     min-width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="openpopEditTestItems(scope.row)">编辑</el-link>
                            <el-popconfirm confirm-button-text="删除"
                                           cancel-button-text="取消"
                                           icon="el-icon-info"
                                           icon-color="red"
                                           @confirm="del(scope.row)"
                                           title="是否确认删除，绑定了该检测项的设备也将失去该检测项。">
                                <el-link type="danger"
                                         slot="reference">删除</el-link>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>
        <!-- 新增 | 编辑 检测项 -->
        <PopEditTestItems v-if="popEditTestItemsVisible"
                          :editInfo="editInfo"
                          @update="getList"
                          @close="closepopEditTestItems"></PopEditTestItems>

    </div>

</template>

<script>
import PopEditTestItems from "./components/popEditTestItems";
import { Loading } from "element-ui";
import Pagination from "@/components/Pagination"; // 分页
import API from "@/api/safeguardManage.js";
export default {
    name: "hardwareDetectionManage",

    props: [],

    components: {
        PopEditTestItems,
        Pagination,
    },

    data() {
        return {
            // 筛选表单
            searchForm: {
                detectType: "YJJC", // 能力
                name: "", // 检测项
                page: 1,
                size: 20,
            },

            pageTotal: 0, // 分页总数
            tableData: {
                data: [],
            },
            editInfo: null, // 编辑
            popEditTestItemsVisible: false, // 新增 | 编辑 检测项弹窗显示
        };
    },

    created() {},

    /* 组件缓存触发 */
    activated() {
        // 硬件检测项管理 列表
        this.getList();
    },

    mounted() {
        // 硬件检测项管理 列表
        this.getList();
    },

    methods: {
        // 硬件检测项管理 列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.getPowerLis(this.searchForm)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },

        // 改变分页
        paginationChange(e) {
            this.searchForm.size = e.limit;
            this.searchForm.page = e.page;
            this.getList();
        },

        // 删除
        del(row) {
            this.loading = true;
            API.delPower(row.id)
                .then(() => {
                    this.loading = false;
                    // 列表
                    this.getList();
                    this.$message({
                        message: "操作成功！",
                        type: "success",
                    });
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        //清空筛选条件
        resetSearchForm() {
            this.searchForm.name = "";
            this.getList();
        },
        // 新增 | 编辑 检测项 打开弹框
        openpopEditTestItems(row = null) {
            this.editInfo = row;
            this.popEditTestItemsVisible = true;
        },
        // 新增 | 编辑 检测项 关闭弹框
        closepopEditTestItems() {
            this.editInfo = null;
            this.popEditTestItemsVisible = false;
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 245 });
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
</style>
