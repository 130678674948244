// 新增 | 编辑 检测项
// popEditTestItems
<template>

    <div class="popEditTestItems">
        <el-dialog :title="`${editInfo ? '编辑' : '新增'}检测项`"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   width="30%"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     label-width="80px"
                     v-loading="detailsLoading"
                     :inline="false">
                <el-form-item label="检测项"
                              prop="name">
                    <el-input v-model="form.name"
                              placeholder="请输入检测项"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="能力描述"
                              prop="remark">
                    <el-input v-model="form.remark"
                              type="textarea"
                              rows="5"
                              placeholder="请输入能力描述"
                              clearable></el-input>
                </el-form-item>
                <div class="footer"
                     v-loading="loading">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="save">保存</el-button>
                </div>
            </el-form>

        </el-dialog>

    </div>
</template>

<script>
import API from "@/api/safeguardManage.js";
export default {
    name: "popEditTestItems",

    props: {
        // 编辑的内容
        editInfo: {
            type: Object,
            default: null,
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,
            form: {
                detectType: "YJJC", // 能力
                name: "", // 检测项
                remark: "", // 能力描述
            },
            formRules: {
                name: {
                    required: true,
                    message: "请验证检测项",
                    trigger: "blur",
                }, // 检测项
            },
            loading: false, // 保存加载中
            detailsLoading: false, // 详情加载中
        };
    },

    created() {},

    mounted() {
        if (this.editInfo) {
            // 详情
            this.getDetails();
        }
    },

    methods: {
        // 保存
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    API.savePower(this.form)
                        .then(() => {
                            this.loading = false;
                            this.$message({
                                message: "操作成功！",
                                type: "success",
                            });
                            this.$emit("update");
                            this.close();
                        })
                        .catch(() => {
                            this.loading = false;
                        });
                }
            });
        },
        // 详情
        getDetails() {
            this.detailsLoading = true;
            API.getPowerDetails(this.editInfo.id)
                .then((res) => {
                    this.detailsLoading = false;
                    this.form = Object.assign({}, res.content, {});
                })
                .catch(() => {
                    this.detailsLoading = false;
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>